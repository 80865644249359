import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Styled from 'styled-components'

import Layout from '../layouts/layout'
import {StyledHeader} from '../layouts/layoutStyles'

import PicWithDesc from '../components/PicWithDesc'
import NaszeRealizacje from "../components/NaszeRealizacje"
import Description from '../components/Description'

import banerjpg from '../images/headerPhotos/home.jpg'
import zaufanie from '../images/icons/6.png'
import precyzja from '../images/icons/7.png'
import szybkosc from '../images/icons/8.png'

const indexPage = ({data}) => {
  return(
    <Layout
      headerPhoto={ <img src={banerjpg} alt="home"/>}
      prefooterElement={<Description/>}
    >
        <StyledHeader className="d-sm-block d-lg-none">Home</StyledHeader>
        <StyledHeader>WITAMY SERDECZNIE</StyledHeader>
        <Row noGutters>
          <Col xs={12} lg={4}>
            <PicWithDesc picture={<Picture src={zaufanie} alt="Zaufanie"/>}
              title="ZAUFANIE"
              description="Każdy z naszych klientów potwierdza jakość naszych
              usług i wybiera nas ponownie przy kolejnych zleceniach."
            />
          </Col>
          <Col xs={12} lg={4}>
            <PicWithDesc picture={<Picture src={szybkosc} alt="Szybkosc"/>}
              title="SZYBKOŚĆ"
              description="Zawsze staramy się aby czas realizacji był jak najkrótszy."
            />
          </Col>
          <Col xs={12} lg={4}>
            <PicWithDesc picture={<Picture src={precyzja} alt="Prezycja"/>}
              title="PRECYZJA"
              description="Wkładamy mnóstwo pracy w to, by zawsze sprostać oczekiwaniom klienta."
            />
          </Col>
        </Row>
        <StyledRow noGutters className="p-5d-flex justify-content-center">
          <Col xs={12} lg={10}>
            <NaszeRealizacje/>
          </Col>
        </StyledRow>
      
    </Layout>
  )
}

export default indexPage

const Picture = Styled.img`
  width: 50%;
`;

const StyledRow = Styled(Row)`
  @media only screen and (max-width:992px){
    padding:0;
  }
`