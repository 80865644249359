import React from 'react'
import styled from 'styled-components'

export default  ({picture,title,description}) =>{
    return(
        <PictureWithDescription>
            {picture}
            <Title>
                {title}
            </Title>
            <Hr />
            <Description>
                {description}
            </Description>
        </PictureWithDescription>
    );
}

const PictureWithDescription = styled.figure`
    max-width: 10 vw;
    text-align: center;

`;

const Title = styled.figcaption`
    position: relative;
    width: 80%;
    left: 50%;
    transform: translate(-50%);
    padding: 1.5rem;
    font-weight: 700;
    font-size: 1.8rem;
`;

const Hr = styled.hr`
    width: 80%;
    background-color:var(--orange);
`;

const Description = styled.figcaption`
    position: relative;
    width: 80%;
    left: 50%;
    transform: translate(-50%);
    padding-top: 1rem;
    font-weight: 100;
    text-transform: none;
    text-justify: inter-word;
    font-size: 1.4rem;
`;
